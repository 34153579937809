<template>
  <div>
    <div class="page page-game-giftcodes" v-if="game">
      <b-row style="margin-top: -15px">
        <GeneralGiftCodesPanel v-if="generalCodes && generalCodes.length" :items="generalCodes"/>
      </b-row>

      <EventGiftCodesPanel v-if="eventCodes && eventCodes.length" :items="eventCodes"/>
      <LoadingPanel v-if="loading"/>
    </div>
    <NotFoundPanel v-else />
  </div>
</template>

<script>
import GeneralGiftCodesPanel from "@/components/panels/giftcode/GeneralGiftCodesPanel";
import EventGiftCodesPanel from "@/components/panels/giftcode/EventGiftCodesPanel";
import giftcodeService from "@/services/giftcodeService";
import dialogHelper from "@/helpers/dialogHelper";

export default {
  name: "Index",
  components: {GeneralGiftCodesPanel, EventGiftCodesPanel},
  data() {
    return {
      game: null,
      loading: false,
      generalCodes: [],
      eventCodes: [],
      take: 20,
      skip: 0,
      ended: false
    }
  },
  async created() {
    this.$bridge.$on("setCurrentGame", this.loadCodes);
  },
  mounted() {
    this.$bridge.$on("scrollToBottom", this.loadData);
  },
  beforeDestroy() {
    this.$bridge.$off("setCurrentGame", this.loadCodes);
    this.$bridge.$off("scrollToBottom", this.loadData);
  },
  methods: {
    async loadCodes() {
      this.game = this.$store.getters.currentGame;
      if (!this.game)
        return;

      this.loading = true;
      const response = await giftcodeService.getCodesInGame({
        idGame: this.game.id,
        takeEvents: this.take
      });
      this.loading = false;
      if (!response)
      {
        await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        return;
      }

      if (response.error) {
        await dialogHelper.alert(response.message);
        return;
      }

      let codes = response.data;
      this.generalCodes = codes.generalCodes;
      codes = codes.eventCodes;
      codes.forEach(code => code.imageUrl = code.type.imageUrl);
      const totalCodes = codes.length;
      this.eventCodes = codes;
      this.skip = totalCodes;
      this.ended = this.take > totalCodes;
    },
    async loadData() {
      if (this.loading || this.ended)
        return;

      this.loading = true;
      const response = await giftcodeService.getEventCodesInGame({
        idGame: this.game.id,
        take: this.take,
        skip: this.skip
      });
      this.loading = false;
      if (!response)
      {
        await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        return;
      }

      if (response.error) {
        await dialogHelper.alert(response.message);
        return;
      }

      let codes = response.data;
      codes.forEach(code => code.imageUrl = code.type.imageUrl);
      const totalCodes = codes.length;
      this.eventCodes = [...this.eventCodes, ...codes];
      this.skip += totalCodes;
      this.ended = this.take > totalCodes;
    }
  }
}
</script>

<style scoped>

</style>
